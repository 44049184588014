import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import metadata from "@data/metadata.json"
import metaImage from "@media/meta/trailers_thumbnail.png"

const PageTitle = ({ page }) => (
  <Helmet
    title={metadata[page] ? metadata[page].title : "ERROR"}
    htmlAttributes={{ lang: "en" }}
    meta={[
      { name: "description", content: metadata[page] ? metadata[page].description : "ERROR" },
      { name: "keywords", content: metadata[page] ? metadata[page].keywords : "ERROR" },
      { name: "og:url", content: metaImage },
      { name: "og:type", content: "website" },
      { name: "fb:app_id", content: "1561710817415396" },
      { name: "og:title", content: "CineSend Trailers | Easy trailer delivery for Canadian Cinemas" },
      { name: "og:description", content: "CineSend Trailers is an online portal for Canadian cinema exhibitors to download DCP trailers for current and upcoming releases." },
      { name: "og:image", content: metaImage },
      { name: "og:image:secure_url", content: metaImage },
      { name: "og:image:type", content: "image/jpeg" },
      { name: "og:site", content: "@cinesend" },
      { name: "og:creator", content: "@cinesend" },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:site", content: "@cinesend" },
      { name: "twitter:title", content: "CineSend Trailers | Easy trailer delivery for Canadian Cinemas" },
      { name: "twitter:description", content: "CineSend Trailers is an online portal for Canadian cinema exhibitors to download DCP trailers for current and upcoming releases." },
      { name: "twitter:image", content: metaImage },
      { name: "google-site-verification", content: "iQfUIYrtbVvb9xZMkJBekI2UPcJgykvy-SXh_9cMblk" }
    ]}
  />
)

PageTitle.propTypes = {
  page: PropTypes.string.isRequired
}

export default PageTitle
