const utilsCSS = `
  body {
    background: white;
    color: #545C63;
  }

  *:not(div):focus {
    outline: auto rgba(53, 49, 73, 0.1) !important;
    outline-offset: .15em !important;
  }

  h1, h2, h3, h4, strong, .bold {
    font-weight: 700;
  }

  p {
    line-height: 23px;
    &.large-p-tag {
      font-size: 18px;
      line-height: 29px;
      letter-spacing: 0.5px;
    }
  }

  .py5 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .py6 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .my5 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  button {
    transition: transform .2s linear;
    &.web_white_outline {
      box-shadow: 0 2px 6px 0 rgba(197,197,197,0.47);
      border: 1px solid #FFFFFF;
      background: transparent;
    }
    &.web_white {
      background: linear-gradient(0deg, #F7F7F7 0%, #FFFFFF 100%);
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.31);
      color: #FB0F3B;
    }
    &:not(.link):not(.normalized-btn):hover {
      transform: translateY(-2px);
    }
    &.normalized-btn {
      height: auto;
      background: inherit;
      color: inherit;
      font-size: inherit;
      min-width: 0;
    }
    &::-moz-focus-inner {
      border: 0;
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }

  * {
    ::-moz-focus-inner {
      border: 0;
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }

  .calendly-popup {
    .calendly-popup-content {
      .spinner {
        left: calc(50% - 22px);
        > * {
          display: none;
        }
      }
    }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .max-width-5 {
    max-width: 75rem;
  }

  html {
    scroll-behavior: smooth;
  }
  
  @media screen and (prefers-reduced-motion: reduce) {
    html {
      scroll-behavior: auto;
    }
  }
`

export default utilsCSS
