import React from "react"
import Link from "gatsby-link"
import { css } from "@emotion/core"
import Fade from "@app-components/transition/fade"

const CTA = () => (
  <div className="center white px2 md-px3 py5 overflow-hidden" css={css`
    background: radial-gradient(circle, #6B6B6B 0%, #505050 0%, #000 100%);
  `}>
    <Fade tagName="div" opacity>
      <div className="max-width-5 mx-auto">
        <h2>Ready to get started?</h2>
        <p className="large-p-tag">Sign up today for a faster and easier way to get your trailers.</p>
        <div className="block sm-flex items-center justify-center mt3">
          <span className="mx1 my1 block sm-inline-block">
            <button
              disabled={true}
              tabIndex="-1"
              css={css`
                width: 155px;
              `}>
              Coming Soon
            </button>
          </span>
          <Link to="/request" className="mx1 my1 block sm-inline-block">
            <button
              tabIndex="-1"
              css={css`
                width: 155px;
              `}
              className="web_white_outline">
              Request Access
            </button>
          </Link>
        </div>
      </div>
    </Fade>
  </div>
)

export default CTA
