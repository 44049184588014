import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"
import links from "@data/header/links.json"

const Desktop = ({ dark, pinned }) => {
  return (
    <div className="flex-auto xs-hide sm-hide md-flex items-center justify-end">
      {links.links.filter(link => link.show.includes("DESKTOP")).map(link => (
        <div key={link._id} className="mr3 px1">
            <Link to={link.to} style={{ color: pinned ? "#383E43" : "#ffffff", fontSize: "16px" }}>
              {link.label}
            </Link>
        </div>
      ))}
      <button tabIndex="-1" disabled="disabled" className={`small ml1`}>
        <div>Coming Soon</div>
      </button>
    </div>
  )
}

Desktop.propTypes = {
  dark: PropTypes.bool.isRequired,
  pinned: PropTypes.bool.isRequired
}

export default Desktop
