import React, { useState } from "react"
import PropTypes from "prop-types"
import Headroom from "react-headroom"
import Link from "gatsby-link"
import { useMedia } from "react-use"
import { css } from "@emotion/core"
import { ReactComponent as Logo } from "@media/logos/svg/white.svg"
import Desktop from "./components/desktop/"
import Mobile from "./components/mobile/"

const DARK_PAGE_HEADERS = ["features/online-screeners", "products/cinema-hardware"]

const Header = ({ page }) => {
  const [pinned, setPinned] = useState(false)
  const desktop = useMedia("(min-width: 52em)")
  return (
    <header className="absolute col-12 z4">
      <Headroom
        pinStart={300}
        onUnfix={() => setPinned(false)}
        onUnpin={() => setPinned(false)}
        onPin={() => setPinned(true)}>
        <nav
          className="px2 md-px3 py2"
          css={css`
            background-color: ${pinned ? "#ffffff" : "transparent"};
            box-shadow: ${pinned ? "0 0 9px 0 rgba(0, 0, 0, 0.13)" : ""};
          `}>
          <div
            className="flex items-center max-width-5 mx-auto"
            css={css`
              height: 2.5rem;
            `}>
            <Link className="flex items-center" to="/">
              <Logo
                title="CineSend Logo"
                css={css`
                  height: 33px;
                  transition: transform 0.1s ease-in-out;
                  transform: scale(${pinned ? 0.9 : 1});
                  [data-original-color="blue"] {
                    fill: ${pinned ? "#FB0F3B" : "#ffffff"};
                  }
                  [data-original-color="red"] {
                    fill: ${pinned ? "#353149" : "#ffffff"};
                  }
                  @media (max-width: 54em) {
                    transform: scale(0.9);
                  }
                `}
                alt="CineSend Logo"
              />
              <span css={css`
                color: #FD2A51;
                padding-left: 12px;
                font-size: 1.5em;
                font-weight: bold;
              `}>
                Trailers
              </span>
            </Link>
            {desktop ? <Desktop dark={DARK_PAGE_HEADERS.includes(page)} pinned={pinned} /> : <Mobile pinned={pinned} />}
          </div>
        </nav>
      </Headroom>
    </header>
  )
}

Header.propTypes = {
  page: PropTypes.string.isRequired
}

export default Header
